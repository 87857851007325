import './App.css';
import { createTheme, ThemeProvider } from '@mui/material';
import { useState, useEffect } from 'react';
import { isAuth } from './helpers/auth';
import Main from "./components/Main";
import Header from "./components/Header";

const theme = createTheme({
  palette: {
    text: {
      primary: '#044c74',
      secondary: '#0B0014',
    },
    action: {
      active: '#001E3C',
    },
    error: {
      main: "#BF4E30"
    },
    success: {
      main: "#499167"
    }
  },
});

function App() {

  const [requiresAuth, setRequiresAuth] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const auth = await isAuth();
      setRequiresAuth(!auth);
    }
    fetchData();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Header requiresAuth = {requiresAuth}/>
      <Main requiresAuth = {requiresAuth}/>
    </ThemeProvider>
  );
}

export default App;
