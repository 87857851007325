import React, { useState, useEffect } from 'react';
import { Grid, Typography, Card, Button, CardContent, CardActionArea, Skeleton, Grow } from '@mui/material';
import { getProjects } from '../helpers/axios';
import { isAuth } from '../helpers/auth';
import IProject, { Auth } from '../types/types';

function Main({ requiresAuth }: Auth) {
    const [projects, setProjects] = useState<IProject[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const auth = await isAuth();
            if (auth) {
                const result = await getProjects();
                setProjects(result);
            }
        };
        fetchData();
    }, []);

    return (
        <Grid container spacing={3}>
            {!requiresAuth ? (
                projects.map((project, index) => {
                    const availableRoute = project.route.INNO || project.route.LIVE || project.route.DEV || project.route.TEST || project.route.STAGING;
                    return (
                        <Grow key={availableRoute || index} style={{ transformOrigin: '0 0 0' }} {...{ timeout: 500 * index }}>
                            <Project {...project} />
                        </Grow>
                    );
                })
            ) : (
                <React.Fragment>
                    {[...Array(4)].map((_, index) => (
                        <Grow key={index}>
                            <Placeholder />
                        </Grow>
                    ))}
                </React.Fragment>
            )}
        </Grid>
    );
}

function Project({ name, description, route }: IProject) {
    const environments: (keyof IProject["route"])[] = ['INNO', 'LIVE', 'STAGING', 'TEST', 'DEV'];
    const buttonColors: Record<string, string> = {
        DEV: '#f77f00',
        TEST: '#ffb703',
        STAGING: '#06d6a0',
        LIVE: '#118ab2',
        INNO: '#073b4c',
    };

    return (
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3} alignItems="center"  style={{ marginBottom: "40px" }}>
            <Card className="card" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', backgroundColor: '#ffffff', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)' }}>
                <CardContent sx={{ flex: '1 1 auto' }}>
                    <Typography sx={{ fontSize: 18, textAlign: "center", fontWeight: "bold" }} color="text.primary" gutterBottom>
                        {name}
                    </Typography>
                    <Typography sx={{ fontSize: 15, textAlign: "justify" }} color="text.secondary" gutterBottom>
                        {description}
                    </Typography>
                </CardContent>
                <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', paddingBottom: '10px' }}>
                    {environments.map((env) => (
                        route[env] && (
                            <a key={env} href={route[env]} target="_blank" rel="noreferrer">
                                <Button variant="contained" style={{ backgroundColor: buttonColors[env], margin: '5px', color: 'white' , borderRadius: '7px'}}>
                                    {env}
                                </Button>
                            </a>
                        )
                    ))}
                </div>
            </Card>
        </Grid>
    );
}

function Placeholder() {
    return (
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3} alignItems="center">
            <Card className="card" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', backgroundColor: '#ffffff', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)' }}>
                <CardContent sx={{ flex: '1 1 auto' }}>
                    <Typography sx={{ fontSize: 24 }} gutterBottom>
                        <Skeleton variant="text" />
                    </Typography>
                    <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </Typography>
                </CardContent>
                <CardActionArea sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', paddingBottom: '10px' }}>
                    <Button disabled variant="contained" color="success">
                        LIVE
                    </Button>
                    <Button disabled variant="contained" color="success">
                        STAGING
                    </Button>
                    <Button disabled variant="contained" color="success">
                        TEST
                    </Button>
                </CardActionArea>
            </Card>
        </Grid>
    );
}

export default Main;