import axios from "axios";
import { logout } from "./auth";

const instance = axios.create({
    baseURL: process.env.REACT_APP_BACK || "",
    headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token") || "undefined"}`
    }
});


export async function auth(user: string, password: string) {
    const token = await instance.post("/auth", {
        "username": user,
        "password": password
    });
    window.localStorage.setItem("token", token.data.token);
    window.location.reload();
}

export async function updatePassword(newPassword: string) {
    await instance.post("/changePassword", {
        "password": newPassword
    });
}

export async function getProjects() {
    try {
        const result = await instance.get("/projects");
        return result.data;
    }
    catch(error) {
        logout();
    }
}

export async function verifyToken() {
    await instance.post("/verify_token", {
        route: "/"
    });
}