import { Grid, Button, Container, Popover, TextField, FormControl, FormGroup, Snackbar, Alert, Box } from '@mui/material';
import React, { MouseEvent, useState } from 'react';
import { logout } from "../helpers/auth";
import { auth, updatePassword } from '../helpers/axios';
import { Auth } from '../types/types';


function Header({ requiresAuth }: Auth) {
    const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClickChangePassword= (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseChangePassword = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    const onClickLogout = () => {
        logout();
    };
    return (
        <header>
            <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
                sx={{ height: "20vh" }}
            >
                <Grid
                    item
                    container
                    direction="column"
                    sx={{ fontSize: 18, textAlign: "center", fontWeight: "bold" }}
                    color="text.secondary"
                    alignItems="center"
                    justifyContent="center">
                    <Grid item>
                        <img alt="MTO MedtecOnline Dashboard and Control Center" src="https://medteconline.de/images/landingpage/logo-mit-schriftzug.svg" />
                    </Grid>
                    <Grid>
                        Dashboard
                    </Grid>
                </Grid>

            </Grid>
            <Box sx={{ "paddingBottom": "20px", display: "flex", justifyContent: "space-between" }}>
                {requiresAuth ?
                    <React.Fragment>
                        <Button aria-describedby="login" variant="contained" onClick={handleClick}>
                            Login
                        </Button>
                        <Popover
                            id="login"
                            open={open}
                            //@ts-ignore
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <LoginForm />
                        </Popover>
                    </React.Fragment>
                    :
                    <Button variant="contained" color="error" onClick={onClickLogout}>
                        Logout
                    </Button>}
                    {!requiresAuth ?
                    <React.Fragment>
                        <Button aria-describedby="changePassword" variant="contained" onClick={handleClickChangePassword}>
                            Change Password
                        </Button>
                        <Popover
                            id="changePassword"
                            open={open}
                            //@ts-ignore
                            anchorEl={anchorEl}
                            onClose={handleCloseChangePassword}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <ChangePasswordForm />
                        </Popover>
                    </React.Fragment>
                    :
                    ""}
            </Box>
        </header>
    );
}

function LoginForm() {
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [showAlert, setShowAlert] = useState<boolean>(false);


    const onClickLogin = async () => {
        try {
            await auth(username, password);
        }
        catch {
            setShowAlert(true);
        }
    }
    return (
        <FormGroup sx={{ "margin": "20px" }}>
            <FormControl>
                <TextField onChange={(el) => setUsername(el.target.value)} label="Username" variant="outlined" id="username-input" />
            </FormControl>
            <FormControl sx={{ "paddingTop": "10px", "paddingBottom": "10px" }}>
                <TextField onChange={(el) => setPassword(el.target.value)} label="Password" variant="outlined" id="password-input" type="password" />
            </FormControl>
            <Button variant="contained" onClick={onClickLogin}>
                Login
            </Button>
            <Snackbar open={showAlert} autoHideDuration={6000} onClose={() => setShowAlert(false)}>
                <Alert onClose={() => setShowAlert(false)} severity="error" variant="filled" sx={{ width: '100%' }}>
                    Wrong username or password.
                </Alert>
            </Snackbar>
        </FormGroup>
    )
}

function ChangePasswordForm() {
    const [password, setPassword] = useState<string>("");
    const [repeatPassword, setRepeatPassword] = useState<string>("");
    const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
    const [showDifferentAlert, setShowDifferentAlert] = useState<boolean>(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);


    const onClickChange = async () => {
        try {
            if (!password || password.length < 4 || password !== repeatPassword) {
                setShowDifferentAlert(true);
            }
            else{
                await updatePassword(password)
                setShowSuccessAlert(true);
            }
        }
        catch {
            setShowErrorAlert(true);
        }
    }
    return (
        <FormGroup sx={{ "margin": "20px" }}>
            <FormControl>
                <TextField onChange={(el) => setPassword(el.target.value)} label="Password" variant="outlined" id="password-input" type = "password" />
            </FormControl>
            <FormControl sx={{ "paddingTop": "10px", "paddingBottom": "10px" }}>
                <TextField onChange={(el) => setRepeatPassword(el.target.value)} label="Repeat password" variant="outlined" id="repeat-password-input" type="password" />
            </FormControl>
            <Button variant="contained" onClick={onClickChange}>
                Change Password
            </Button>
            <Snackbar open={showErrorAlert} autoHideDuration={6000} onClose={() => setShowErrorAlert(false)}>
                <Alert onClose={() => setShowErrorAlert(false)} severity="error" variant="filled" sx={{ width: '100%' }}>
                    Something went wrong
                </Alert>
            </Snackbar>
            <Snackbar open={showDifferentAlert} autoHideDuration={6000} onClose={() => setShowDifferentAlert(false)}>
                <Alert onClose={() => setShowDifferentAlert(false)} severity="error" variant="filled" sx={{ width: '100%' }}>
                    The passwords are not the same and they should be at leat 4 characters long
                </Alert>
            </Snackbar>
            <Snackbar open={showSuccessAlert} autoHideDuration={6000} onClose={() => setShowSuccessAlert(false)}>
                <Alert onClose={() => setShowSuccessAlert(false)} severity="success" variant="filled" sx={{ width: '100%' }}>
                    Password changed succesfully
                </Alert>
            </Snackbar>

        </FormGroup>
    )
}
export default Header;
